import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { APIUrls } from '../enums/apiUrls';
import { BusinessAreaDataResponse } from '../interfaces/Admin/BusinessReporting/business-reporting-request-response';
import { PhaseListByHeadlinePracticeAreaResponse } from '../interfaces/Admin/Matter/PhaseListByHeadlinePracticeAreaResponse';
import { FeatureListByRoleTypeId } from '../interfaces/Admin/Role/FeatureListByRoleTypeId';
import { HeadLinePracticeAreaResponse } from '../interfaces/Admin/Role/HeadLinePracticeAreaNyRoleIdRespomse';
import {
  MemberTypeListByRoleIdResponse,
  MemberTypeListResponse,
} from '../interfaces/Admin/Role/MemberTypeListResponse';
import { RoleAddRequest } from '../interfaces/Admin/Role/RoleAddRequest';
import { Permission, RoleDetailsByRoleIdResponse } from '../interfaces/Admin/Role/RoleDetailsByRoleIdResponse';
import { RoleListRequest } from '../interfaces/Admin/Role/RoleListRequest';
import { RoleListResponse } from '../interfaces/Admin/Role/RoleListResponse';
import { RolesResponse } from '../interfaces/Admin/Role/RolesResponse';
import { RoleTypesResponse } from '../interfaces/Admin/Role/RolesTypeResponse';
import { SubPracticeAreaByHeadlineIdResponse } from '../interfaces/Admin/Role/SubPracticeAreaByHeadlineIdResponse';
import {
  SystemConfigEditRequest,
  SystemConfigurationEditRequestForLegalTeam,
} from '../interfaces/Admin/Role/SystemConfigEditRequest';
import { SystemConfigResponse } from '../interfaces/Admin/Role/SystemConfigResponse';
import { UserListByRoleIdResponse } from '../interfaces/Admin/Role/UserListByRoleIdResponse';
import { SuccessResponse } from '../interfaces/Admin/SuccessResponse';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private permissionSubject = new BehaviorSubject<Permission[]>([]);
  allObservable: Observable<Permission[]> = this.permissionSubject.asObservable();

  userPermissions = toSignal(this.allObservable);

  private headlinePracticeAreas: HeadLinePracticeAreaResponse;

  constructor(private _apiService: ApiService) {}

  setUpdatedData(data: Permission[]) {
    this.permissionSubject.next(data);
  }

  getRoleTypes(): Observable<RoleTypesResponse> {
    return this._apiService.get<RoleTypesResponse>(APIUrls.GetRoleTypes);
  }

  getHeadlinePracticeArea(): Observable<HeadLinePracticeAreaResponse> {
    if (this.headlinePracticeAreas) {
      return of(this.headlinePracticeAreas);
    } else {
      return this._apiService
        .get<HeadLinePracticeAreaResponse>(APIUrls.GetHeadlinePracticaArea)
        .pipe(tap((data) => (this.headlinePracticeAreas = data)));
    }
  }

  getBusinessReportAreas(): Observable<BusinessAreaDataResponse> {
    return this._apiService.get<BusinessAreaDataResponse>(APIUrls.GetBusinessArea);
  }

  getPhasesByHeadlinePracticeArea(headlinePracticeAreaIDs: number[]) {
    return this._apiService.post<PhaseListByHeadlinePracticeAreaResponse>(
      APIUrls.GetPracticeAreaHeadPhaseListByHeadlineId,
      [...headlinePracticeAreaIDs],
    );
  }

  getSubPracticeAreaByHPAId(headlineId: number[]): Observable<SubPracticeAreaByHeadlineIdResponse> {
    return this._apiService.post<SubPracticeAreaByHeadlineIdResponse>(
      APIUrls.GetSubPracticeAreaByHeadlinePracticeAreaId,
      { headlineId },
    );
  }

  getRoles(): Observable<RolesResponse> {
    return this._apiService.get<RolesResponse>(APIUrls.GetAllRoles);
  }

  getRoleByRoleType(id: number): Observable<RolesResponse> {
    return this._apiService.get<RolesResponse>(`${APIUrls.GetRoleByRoleType}/${id}`);
  }

  getRoleList(roleListReq: RoleListRequest): Observable<RoleListResponse> {
    return this._apiService.post<RoleListResponse>(APIUrls.GetRoleList, { ...roleListReq });
  }

  checkRoleName(roleName: string): Observable<SuccessResponse> {
    return this._apiService.get<SuccessResponse>(`${APIUrls.CheckRoleName}/${roleName}`);
  }

  getUserListByRoleId(id: number): Observable<UserListByRoleIdResponse> {
    return this._apiService.get<UserListByRoleIdResponse>(`${APIUrls.GetUserListByRoleId}/${id}`);
  }

  getFeatureListById(id: number): Observable<FeatureListByRoleTypeId> {
    return this._apiService.get<FeatureListByRoleTypeId>(`${APIUrls.GetFeatureListById}/${id}`);
  }

  addRole(addRoleReq: RoleAddRequest): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.AddRole, { ...addRoleReq });
  }

  editRole(addRoleReq: RoleAddRequest): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.EditRole, { ...addRoleReq });
  }

  deleteRole(id: number): Observable<SuccessResponse> {
    return this._apiService.get<SuccessResponse>(`${APIUrls.DeleteRole}/${id}`);
  }

  getRoleDetailsByRoleId(id: number): Observable<RoleDetailsByRoleIdResponse> {
    return this._apiService.get<RoleDetailsByRoleIdResponse>(`${APIUrls.GetRoleDetailsByRoleId}/${id}`);
  }

  getRolePermissionByRoleId(id: number): Observable<RoleDetailsByRoleIdResponse> {
    return this._apiService.get<RoleDetailsByRoleIdResponse>(`${APIUrls.getRolePermissionByRoleId}/${id}`);
  }

  getMemberTypeList(): Observable<MemberTypeListResponse> {
    return this._apiService.get<MemberTypeListResponse>(APIUrls.GetMemberTypeList);
  }

  getMemberTypeByRoleId(roleId: number): Observable<MemberTypeListByRoleIdResponse> {
    return this._apiService.get<MemberTypeListByRoleIdResponse>(APIUrls.GetMemberTypeByRoleId, { roleId });
  }

  // system configuration
  getSystemConfigurations(): Observable<SystemConfigResponse> {
    return this._apiService.get<SystemConfigResponse>(APIUrls.GetSystemConfigurations);
  }

  editSystemConfiguration(req: SystemConfigEditRequest): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.UpdateSystemConfigurations, { ...req });
  }

  editSystemConfigurationForLegalTeam(req: SystemConfigurationEditRequestForLegalTeam): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.UpdateUserPreferences, { ...req });
  }
}
